/* eslint-disable no-nested-ternary */
const api = (() => {
  const BASE_URL = process.env.REACT_APP_BASE_API_URL;

  /**
   * Get Access Token
   * @returns accessToken
   */
  function getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  /**
   * Put Access Token
   * @param {*} token
   */
  function putAccessToken(token) {
    localStorage.setItem('accessToken', token);
  }

  /**
   * Fetch With Auth
   * @param {*} url
   * @param {*} options
   * @returns fetch
   */
  async function fetchWithAuth(url, options = {}) {
    return fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    });
  }

  /**
   * Get User Profile
   * @returns user profile
   */
  async function getOwnProfile() {
    const response = await fetchWithAuth(`${BASE_URL}/users/me`);

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { user } } = responseJson;

    return user;
  }

  /**
   * Get All Users
   * @returns
   */
  async function getAllUsers() {
    const response = await fetch(`${BASE_URL}/users`);

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { users } } = responseJson;

    return users;
  }

  /**
   * Get All Threads
   * @returns
   */
  async function getAllThreads() {
    const response = await fetch(`${BASE_URL}/threads`);

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { threads } } = responseJson;

    return threads;
  }

  /**
   *
   * @param {*} threadId
   * @returns
   */
  async function getThreadDetail(threadId) {
    const response = await fetch(`${BASE_URL}/threads/${threadId}`);

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { detailThread } } = responseJson;

    return detailThread;
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  async function createThread({ body, category, title }) {
    const response = await fetchWithAuth(`${BASE_URL}/threads`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        body,
        category,
        title,
      }),
    });

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { thread } } = responseJson;

    return thread;
  }

  /**
   * Toggle Vote Thread
   * @param {string} threadId
   * @param {string} voteType => 1 | -1 | 0
   */
  async function toggleVoteThread(threadId, voteType) {
    if (![-1, 0, 1].includes(voteType)) {
      alert('voteType: -1 | 0 | 1');
      return null;
    }
    const mode = voteType === 1 ? 'up'
      : voteType === 0 ? 'neutral'
        : voteType === -1 ? 'down'
          : 'up';
    const response = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/${mode}-vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
    return true;
  }

  /**
   *
   * @param {*} param0
   * @returns
   */
  async function createComment({ threadId, content }) {
    const response = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        content,
      }),
    });

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
  }

  /**
   * Toggle Vote Thread
   * @param {string} threadId
   * @param {string} voteType => 1 | -1 | 0
   */
  async function toggleVoteComment(threadId, commentId, voteType) {
    if (![-1, 0, 1].includes(voteType)) {
      alert('voteType: -1 | 0 | 1');
      return null;
    }
    const mode = voteType === 1 ? 'up'
      : voteType === 0 ? 'neutral'
        : voteType === -1 ? 'down'
          : 'up';
    const response = await fetchWithAuth(`${BASE_URL}/threads/${threadId}/comments/${commentId}/${mode}-vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }
    return true;
  }

  /**
   * Get Leaderboards
   * @returns
   */
  async function getLeaderboards() {
    const response = await fetch(`${BASE_URL}/leaderboards`);

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { leaderboards } } = responseJson;

    return leaderboards;
  }

  /**
   * Login
   * @param {*} param0
   * @returns
   */
  async function login({ email, password }) {
    const response = await fetch(`${BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });

    const responseJson = await response.json();

    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    const { data: { token } } = responseJson;

    return token;
  }

  /**
   * Register
   * @param {*} param0
   * @returns
   */
  async function register({ name, email, password }) {
    const response = await fetch(`${BASE_URL}/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        email,
        password,
      }),
    });

    const responseJson = await response.json();
    const { status, message } = responseJson;

    if (status !== 'success') {
      throw new Error(message);
    }

    /**
     * Return responseJson
     * supaya dapat message
     */
    return responseJson;
  }

  return {
    getAccessToken,
    putAccessToken,
    fetchWithAuth,
    getOwnProfile,
    getAllThreads,
    getAllUsers,
    getLeaderboards,
    getThreadDetail,
    createThread,
    createComment,
    toggleVoteThread,
    toggleVoteComment,
    login,
    register,
  };
})();

export default api;
