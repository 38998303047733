import React from 'react';

function FooterComp() {
  return (
    <footer
      className="w-full text-xs"
    >
      <div
        className="
          max-w-7xl mx-auto
          p-4
          sm:grid sm:grid-cols-2 sm:gap-4
        "
      >
        <p className="text-center sm:text-left leading-8">
          © 2022 Putu Jhonarendra
        </p>
        <p className="text-center sm:text-right leading-8">
          Dicoding React Expert Submission 1
        </p>
      </div>
    </footer>
  );
}

export default FooterComp;
