import React from 'react';
import PropTypes from 'prop-types';
import NavbarComp from '../components/layout/NavbarComp';
import FooterComp from '../components/layout/FooterComp';

function UserLayout({ children }) {
  return (
    <div
      className="
        min-h-screen
        bg-neutral-100
        dark:bg-black
        dark:text-white
      "
    >
      {/* navbar */}
      <NavbarComp />

      {/* main */}
      <main
        className="
          pt-24
          min-h-screen
          border-b
          dark:border-neutral-900
        "
      >
        <div
          className="
            max-w-7xl mx-auto
          "
        >
          { children }
        </div>
      </main>

      {/* footer */}
      <FooterComp />
    </div>
  );
}

UserLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserLayout;
