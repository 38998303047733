import React from 'react';

function LogoBrand() {
  return (
    <span
      className="
        block
        w-8
        h-8
        text-center
        border-2
        border-black
        dark:border-white
        font-bold
        text-xl
      "
    >
      F
    </span>
  );
}

export default LogoBrand;
