import React from 'react';
import { Link } from 'react-router-dom';
import useLocale from '../../hooks/useLocale';

function NotFoundComp() {
  const { txtNotFound, txtGoto, txtHome } = useLocale();

  return (
    <div className="text-center">
      <p className="text-7xl font-bold">404</p>
      <p className="text-xl my-4">{ txtNotFound }</p>
      <p className="text-center">
        <Link
          to="/"
        >
          {txtGoto}
          {' '}
          {txtHome}
        </Link>
      </p>
    </div>
  );
}

export default NotFoundComp;
